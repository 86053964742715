import React from 'react';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {useTextStyles} from 'styles';

const Footer = () => {
  const textStyles = useTextStyles();

  return (
    <AppBar color='default' position='static'>
      <Toolbar>
        <Typography style={{flexGrow: 1}} variant='h6'>
          <Link className={textStyles.link} to={process.env.PUBLIC_URL}>
            Project
          </Link>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
export default Footer;
