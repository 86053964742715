import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';

// Reducers
import session from './services/session';
import countries from './services/countires';
import user from './user';
import projects from './projects';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
  session,
  user,
  countries,
  projects,
});

const store: Store<Store.State> = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
);

export * from './services/session';

export const useAppDispatch = () => useDispatch<Store.AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<Store.State> = useSelector;

export default store;
