import {makeStyles} from '@material-ui/core';

export const useWrapStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    '& .page': {
      flexGrow: 1,
    },
  },
});
