import {makeStyles} from '@material-ui/core';

export const useTextStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  whitespace: {
    whiteSpace: 'pre-line',
  },
});
