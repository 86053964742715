import {toast} from 'react-toastify';
import {Action} from './reducer';
import {LOGIN, LOGOUT} from './types';
import {
  FETCH_USER_FAILURE,
  FETCH_USER_LOADING,
  FETCH_USER_SUCCESS,
} from 'store/user';
import {sendRequest} from 'hooks/useRequest';
import {IUser, UserError} from 'interfaces/user.interface';
import {UserLoginParams} from 'types';

export const login = (): Action => ({
  type: LOGIN,
});

export const logout = (): Action => ({
  type: LOGOUT,
});

export const loginUser = (
  user: UserLoginParams
): ((dispatch: Store.AppDispatch) => Promise<boolean>) => {
  return (dispatch) => {
    dispatch({type: FETCH_USER_LOADING});

    return sendRequest<IUser | UserError>({
      path: '/auth/sign_in',
      body: {user},
      config: {method: 'POST'},
    })
      .then((res) => {
        if (res.isSuccess) {
          dispatch({type: FETCH_USER_SUCCESS, payload: res.body});
          dispatch(login());
        } else {
          throw (res.body as UserError).error;
        }
        return res.isSuccess;
      })
      .catch((error) => {
        dispatch({type: FETCH_USER_FAILURE});
        toast.error(error);
        return false;
      });
  };
};

export const logoutUser = (): ((
  dispatch: Store.AppDispatch
) => Promise<boolean>) => {
  return (dispatch) => {
    return sendRequest({
      path: '/auth/sign_out',
      config: {method: 'DELETE'},
    })
      .then((res) => {
        if (res.isSuccess) {
          dispatch(logout());
        }
        return res.isSuccess;
      })
      .catch((error) => {
        toast.error(error);
        return false;
      });
  };
};
