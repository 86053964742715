import {useMemo} from 'react';
import {Options, OptionsGetter, UnknownObj} from 'types';

export function useSelectOptions(
  data: unknown[] | undefined = [],
  labelKey: OptionsGetter = 'title',
  valueKey: OptionsGetter = 'id'
): Options {
  function getProperty(item: unknown, getter: OptionsGetter) {
    return String(
      typeof getter === 'string' ? (item as UnknownObj)[getter] : getter(item)
    );
  }

  return useMemo(
    () =>
      data.map((item) => ({
        label: getProperty(item, labelKey),
        value: getProperty(item, valueKey),
      })) || [],
    [data, labelKey, valueKey]
  );
}
