import {useCallback, useMemo} from 'react';
import {createSelector} from 'reselect';
import {useHistory} from 'react-router-dom';
import {
  login as loginAction,
  loginUser as loginUserAction,
  logout as logoutAction,
  logoutUser as logoutUserAction,
} from './actions';
import {DashboardRoute} from 'routes/content-routes';
import {useAppDispatch, useAppSelector} from 'store';
import {ProjectsRoute} from 'routes/user-routes';

const useSession = () => {
  // Setup
  const dispatch = useAppDispatch();
  const history = useHistory();

  // Data
  const session = useAppSelector(
    useMemo(
      () =>
        createSelector(
          (state: Store.State) => state.session,
          (status) => status
        ),
      []
    )
  );

  // Handlers
  const login = useCallback(() => dispatch(loginAction()), [dispatch]);
  const logout = useCallback(() => dispatch(logoutAction()), [dispatch]);
  const loginHandler = useCallback(
    async (params) => {
      const isSuccessLogin = await dispatch(loginUserAction(params));
      if (isSuccessLogin) {
        history.push(ProjectsRoute.getPath());
      }
    },
    [dispatch]
  );
  const logoutHandler = useCallback(async () => {
    const isSuccessLogout = await dispatch(logoutUserAction());
    if (isSuccessLogout) {
      history.push(DashboardRoute.getPath());
    }
  }, [dispatch]);

  return {
    isAuthorized: session.isAuthorized,
    login,
    logout,
    loginHandler,
    logoutHandler,
  };
};

export {useSession};
