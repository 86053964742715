import qs from 'qs';
import {useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {UnknownObj} from 'types';

export function useQueryParams<P = UnknownObj>(): {
  params: P;
  setParams: (params: P) => void;
} {
  const location = useLocation();
  const history = useHistory();

  const params = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }) as unknown as P,
    [location.search]
  );

  function setParams(values: P) {
    history.replace({
      pathname: location.pathname,
      search: qs.stringify(values || {}, {
        addQueryPrefix: true,
        encode: true,
        format: 'RFC1738',
      }),
    });
  }

  return {params, setParams};
}
