import {
  FETCH_COUNTRIES_LOADING,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILURE,
} from './types';
import {sendRequest} from 'hooks/useRequest';
import {ICountry} from 'interfaces/servises.interface';

export const fetchCountriesAction = (): ((
  dispatch: Store.AppDispatch
) => Promise<boolean>) => {
  return (dispatch) => {
    dispatch({type: FETCH_COUNTRIES_LOADING});

    return sendRequest<ICountry>({
      path: '/countries',
    })
      .then((res) => {
        if (res.isSuccess) {
          dispatch({type: FETCH_COUNTRIES_SUCCESS, payload: res.body});
        }
        // else {
        //   throw (res.body as UserError).error;
        // }
        return res.isSuccess;
      })
      .catch(() => {
        dispatch({type: FETCH_COUNTRIES_FAILURE});
        return false;
      });
  };
};
