import {UnknownObj} from 'types/common.types';
import {RouterConfig, RouterReturn} from 'types/routes.types';

export default function createRoute<P = UnknownObj>(
  config: RouterConfig
): RouterReturn<P> {
  return {
    ...config,
    getPath(params = {} as P) {
      const values = Object.values(params);
      let counter = 0;
      return config.path
        .replace(/:\w+/g, () => (values as [])[counter++])
        .replace(/\/\//g, '/')
        .replace(/\/$/, '');
    },
  };
}
