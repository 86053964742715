import React from 'react';
import {Box, CircularProgress} from '@material-ui/core';
import clsx from 'clsx';
import {useLoaderStyles} from './loader.styles';
import {LoaderProps} from './loader.types';

const Loader = (props: LoaderProps) => {
  const loaderStyles = useLoaderStyles();

  return (
    <Box className={clsx(loaderStyles.loader, {indent: props.withTopIndent})}>
      <CircularProgress />
    </Box>
  );
};

export default Loader;
