import qs from 'qs';
import config from 'config';
import {RequestConfig, RequestResponseData, UnknownObj} from 'types';

export const sendRequest = async <ResponseData, ReqParams = UnknownObj>(
  props: RequestConfig<ReqParams>
): Promise<RequestResponseData<ResponseData>> => {
  const {path, body, config: reqConfig = {headers: {}}, params} = props;
  const isFormData = body instanceof FormData;
  let requestPath = config.API_HOST + path;
  const requestHeaders: HeadersInit = new Headers({
    'Content-Type': 'application/json',
    ...reqConfig.headers,
  });

  if (isFormData) {
    requestHeaders.delete('Content-Type');
  }

  if (params) {
    requestPath += qs.stringify(params, {addQueryPrefix: true});
  }

  return window
    .fetch(requestPath, {
      method: 'GET',
      credentials: 'include',
      headers: requestHeaders,
      ...reqConfig,
      body: isFormData ? (body as BodyInit) : JSON.stringify(body),
    })
    .then(async (res) => ({
      isSuccess: res.ok,
      body: res.status === 204 ? void 0 : await res.json(),
      status: res.status,
    }));
};
