import lodash from 'lodash';
import {UnknownObj} from 'types';

const hasDuplicates = (a: unknown[]) => _.uniq(a).length !== a.length;

const getCleanObject = (obj: UnknownObj): UnknownObj => {
  return _.transform(obj, function (o, v, k) {
    if (_.isObject(v)) {
      o[k] = _.getCleanObject(v as UnknownObj);
    } else if (!_.isEmpty(v)) {
      o[k] = v;
    }
  });
};

lodash.mixin({
  getCleanObject,
  hasDuplicates,
});
