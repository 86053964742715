import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import * as auth from './auth-routers';
import * as content from 'routes/content-routes';
import * as personal from 'routes/user-routes';
import {useSession} from 'store';
import Loader from 'components/layout/Loader';
// import PageNotFound from 'components/layout/NotFound';
import {useUser} from 'store/user';

export default function MainRouter() {
  const {
    user: {loading, init, isConfirmation, payload},
  } = useUser();
  const {isAuthorized} = useSession();

  if (!init && loading) {
    return <Loader withTopIndent />;
  }

  if (isAuthorized && !payload?.technical_requirements_accepted) {
    return (
      <Switch>
        <Route {...auth.ConfirmationGuidelinesRouter} />
        <Redirect to={auth.ConfirmationGuidelinesRouter.getPath()} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route {...content.DashboardRoute} />
      <Route {...auth.SignInRouter} />
      <Route {...auth.ConfirmationRouter} />
      {/*<Route {...auth.RestoreRouter} />*/}

      {isAuthorized && !isConfirmation && (
        <>
          <Route {...personal.ProfileRoute} />
          <Route {...personal.ProjectsRoute} />
          <Route {...personal.ProjectViewRoute} />
          <Route {...personal.ProjectOfferAcceptRoute} />
          <Route {...personal.ArchiveRoute} />
          {/*<Route path={'*'}>*/}
          {/*  <PageNotFound />*/}
          {/*</Route>*/}
        </>
      )}
    </Switch>
  );
}
