import React from 'react';
import createRoute from './create-router';

const SignIn = React.lazy(() =>
  import(/* webpackChunkName: "sign-in-page" */ 'pages/SignIn')
);

const Confirmation = React.lazy(() =>
  import(/* webpackChunkName: "confirmation-page" */ 'pages/Confirmation')
);

const SignUp = React.lazy(() =>
  import(/* webpackChunkName: "sign-up-page" */ 'pages/SignUp')
);

const ConfirmationGuidelines = React.lazy(() =>
  import(
    /* webpackChunkName: "sign-up-guidelines-page" */ 'pages/ConfirmationGuidelines'
  )
);

// const Restore = React.lazy(() =>
//   import(/* webpackChunkName: "restore-page" */ 'pages/Restore')
// );

export const SignInRouter = createRoute({
  path: '/login',
  component: SignIn,
  exact: true,
});

export const ConfirmationRouter = createRoute({
  path: '/confirmation',
  component: Confirmation,
  exact: true,
});

export const SignUpRouter = createRoute({
  path: '/registration',
  component: SignUp,
  exact: true,
});

export const ConfirmationGuidelinesRouter = createRoute({
  path: '/confirmation/guidelines',
  component: ConfirmationGuidelines,
  exact: true,
});

// export const RestoreRouter = createRoute({
//   path: '/restore',
//   component: Restore,
//   exact: true,
// });
