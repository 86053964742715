import {makeStyles} from '@material-ui/core';

export const useLoaderStyles = makeStyles({
  loader: {
    position: 'absolute',
    backgroundColor: '#fff',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&.indent': {
      top: 84,
    },
  },
});
