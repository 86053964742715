import {
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_LOADING,
  FETCH_PROJECTS_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
} from './types';
import {RequestAction} from 'types';
import {Projects} from 'interfaces/projects.interface';

const initialProjectsState = {
  loading: true,
  payload: {
    finalized: [],
    new_assignments: [],
    in_progress: [],
  },
  init: false,
};

export const projectsReducer = (
  state = initialProjectsState,
  {type, payload, error}: RequestAction<Projects>
): Store.State['projects'] => {
  switch (type) {
    case FETCH_PROJECTS_LOADING:
      return {...state, loading: true};
    case FETCH_PROJECTS_SUCCESS:
      return {...state, payload, init: true, loading: false};
    case FETCH_PROJECTS_FAILURE:
      return {...state, payload, loading: false, error};

    case UPDATE_PROJECT_SUCCESS:
      return {...state, payload};

    default:
      return state;
  }
};
