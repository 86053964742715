import {createMuiTheme} from '@material-ui/core';

export const globalStyles = createMuiTheme({
  palette: {
    primary: {
      light: '#81CD23',
      main: '#77bc1f',
      dark: '#77bc1f',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 14,
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        padding: '10px 0',
        backgroundColor: '#212425',
      },
    },
    MuiTextField: {
      root: {
        '& div.Mui-disabled': {
          background: 'rgba(148, 148, 148, 0.1)',
        },
      },
    },
    MuiPaper: {
      root: {
        '&.project': {
          borderRadius: 15,
        },
      },
    },
    MuiDivider: {
      root: {
        '&.xl-gutter': {
          margin: '20px 0',
        },
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        '&.blockLine': {
          flexWrap: 'wrap',
        },
      },
    },
    MuiListItemText: {
      root: {
        padding: 0,
        marginTop: 0,
        marginBottom: 0,
        flexGrow: 0,
        '&.bold > span': {
          fontWeight: 500,
        },
        '&.longText  > span': {
          wordBreak: 'break-word',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        minWidth: 60,
        '&.min-130': {
          minWidth: 130,
        },
        '&.min-170': {
          minWidth: 170,
        },
      },
    },
    MuiTable: {
      root: {
        '&.no-border .MuiTableCell-root': {
          border: 0,
        },
      },
    },
    MuiTypography: {
      root: {
        textDecoration: 'none',
        '&.longText': {
          wordBreak: 'break-word',
        },
      },
    },
  },
});
