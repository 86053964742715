import {UnknownObj} from 'types/common.types';

export function makeFormData(object: UnknownObj): FormData {
  const fd = new FormData();

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const updKey = key.replace(/\[\d+\]/g, '[]');
      const value = object[key] as string | Blob | [];

      if (value === null || value === undefined) {
        continue;
      }

      if (value instanceof Array) {
        value.forEach((el) => {
          fd.append(updKey, el);
        });
      } else if (value instanceof File) {
        fd.append(updKey, value, value.name);
      } else if (value instanceof Blob) {
        fd.append(updKey, value, value.size.toString());
      } else {
        fd.append(updKey, value);
      }
    }
  }

  return fd;
}

export function makePlainObject(
  treeObject: UnknownObj,
  _prefix?: string
): UnknownObj {
  const result = {} as UnknownObj;
  const isArray = (el: unknown): boolean => el instanceof Array;
  const isObject = (el: unknown): boolean =>
    typeof el === 'object' && el !== null;
  const isFile = (el: unknown): boolean =>
    el instanceof File || el instanceof Blob;

  for (const key in treeObject) {
    if (Object.prototype.hasOwnProperty.call(treeObject, key)) {
      const keyWithPre = _prefix ? `${_prefix}[${key as string}]` : key;
      const current = treeObject[key];

      if (isArray(current)) {
        (current as []).forEach((el: unknown, index: number) => {
          const arrKey = `${keyWithPre}[${index}]`;

          if (isObject(el) && !isFile(el)) {
            Object.assign(result, makePlainObject(el as UnknownObj, arrKey));
          } else {
            result[arrKey] = el;
          }
        });
      } else if (isObject(current) && !isFile(current)) {
        Object.assign(
          result,
          makePlainObject(current as UnknownObj, keyWithPre)
        );
      } else {
        result[keyWithPre] = current;
      }
    }
  }
  return result;
}
