import * as development from './develop';
import * as production from './production';
import * as local from './local';
import * as staging from './staging';
import * as staging2 from './staging2';
import * as common from './common';

function compose(config: Config.ConfigList) {
  return {
    ...common,
    ...config,
  };
}

const makeConfig = (env = 'DEVELOPMENT'): Config.ComposedConfig => {
  switch (env.toUpperCase()) {
    case 'PRODUCTION':
      return compose(production);
    case 'DEVELOPMENT':
      return compose(development);
    case 'LOCAL':
      return compose(local);
    case 'STAGING':
      return compose(staging);
    case 'STAGING2':
      return compose(staging2);
    default:
      return common;
  }
};

export default makeConfig(process.env.REACT_APP_MODE);
