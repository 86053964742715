import {useCallback, useMemo} from 'react';
import {createSelector} from 'reselect';
import {fetchCountriesAction} from './actions';
import {useAppDispatch, useAppSelector} from 'store';
import {useSelectOptions} from 'hooks';

const useCountries = () => {
  // Setup
  const dispatch = useAppDispatch();

  // Data
  const countries = useAppSelector(
    useMemo(
      () =>
        createSelector(
          (state: Store.State) => state.countries,
          (status) => status
        ),
      []
    )
  );

  const countiesOptions = useSelectOptions(countries.payload, 'name');

  // Handlers
  const fetchCountries = useCallback(
    () => dispatch(fetchCountriesAction()),
    [dispatch]
  );

  return {
    fetchCountries,
    countries,
    countiesOptions,
  };
};

export {useCountries};
