import {
  FETCH_COUNTRIES_LOADING,
  FETCH_COUNTRIES_FAILURE,
  FETCH_COUNTRIES_SUCCESS,
} from './types';
import {RequestAction} from 'types';
import {ICountry} from 'interfaces/servises.interface';

const initialState: Store.State['countries'] = {
  loading: true,
  payload: undefined,
  init: false,
};

export default (
  state = initialState,
  {type, payload, error}: RequestAction<ICountry[]>
): Store.State['countries'] => {
  switch (type) {
    case FETCH_COUNTRIES_LOADING:
      return {...state, loading: true};
    case FETCH_COUNTRIES_SUCCESS:
      return {...state, payload, init: true, loading: false};
    case FETCH_COUNTRIES_FAILURE:
      return {...state, payload, loading: false, error};

    default:
      return state;
  }
};
