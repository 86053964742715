import {useCallback, useEffect, useMemo, useState} from 'react';
import {createSelector} from 'reselect';
import {useParams} from 'react-router-dom';
import {UseFormReturn} from 'react-hook-form';
import {DateTime} from 'luxon';
import {toast} from 'react-toastify';
import {
  fetchProjectByIdAction,
  fetchProjectsAction,
  updateProjectByIdAction,
  deleteProjectByIdAction,
} from './actions';
import {useAppDispatch, useAppSelector} from 'store';
import {IProject, Projects} from 'interfaces/projects.interface';
import {
  Integer,
  UpdateProjectOfferStepParams,
  UpdateProjectParams,
} from 'types';

const useProjects = (initFetch = false) => {
  // Setup
  const dispatch = useAppDispatch();

  // Data
  const projects = useAppSelector(
    useMemo(
      () =>
        createSelector(
          (state: Store.State) => state.projects,
          (data) => data
        ),
      []
    )
  );

  // Handlers
  const fetchProjects = useCallback(() => {
    dispatch(fetchProjectsAction());
  }, [dispatch]);

  const fetchProjectById = useCallback(
    (id) => {
      dispatch(fetchProjectByIdAction(id));
    },
    [dispatch]
  );

  const updateProjectById = useCallback(
    (
      params: UpdateProjectParams | UpdateProjectOfferStepParams,
      withToast = true
    ) => {
      dispatch(updateProjectByIdAction(params, withToast));
    },
    [dispatch]
  );

  const deleteProjectById = useCallback(
    (id: Integer) => {
      dispatch(deleteProjectByIdAction(id));
    },
    [dispatch]
  );

  // Effects
  useEffect(() => {
    if (initFetch) {
      fetchProjects();
    }
  }, [fetchProjects, initFetch]);

  // Helpers
  const getCurrencySymbol = useCallback((currency: IProject['currency']) => {
    switch (currency) {
      case 'eur':
        return '€';
      default:
        return '$';
    }
  }, []);

  const checkOnProposal = useCallback(
    (status: IProject['user_status']) =>
      status === 'proposal' || status === 'proposal_denied',
    []
  );

  return {
    projects,
    fetchProjects,
    fetchProjectById,
    updateProjectById,
    deleteProjectById,
    getCurrencySymbol,
    checkOnProposal,
  };
};

const findCurrentProject = (id: string, projectsList: Projects) => {
  const entries = Object.entries(projectsList);

  for (let i = 0; i < entries.length; i++) {
    const [key, list] = entries[i];
    const position = list.findIndex((pr) => pr.id.toString() === id);
    if (position !== -1) {
      return projectsList[key as keyof Projects][position];
    }
  }
};

const useProjectView = (
  formMethods: UseFormReturn<UpdateProjectParams['project']>,
  initFetch = false
) => {
  // Setup
  const [confirm, setConfirm] = useState(false);
  const {id} = useParams<{id: string}>();
  const {projects, fetchProjectById, updateProjectById} = useProjects();

  // Data
  const [
    videosList,
    expensesList,
    latitude,
    longitude,
    selfie_file,
    selfie_timestamp,
  ] = formMethods.watch([
    'videos',
    'additional_expenses',
    'latitude',
    'longitude',
    'selfie_file',
    'selfie_timestamp',
  ]);

  const currentProject = useAppSelector(
    useMemo(
      () =>
        createSelector(
          (state: Store.State) =>
            findCurrentProject(id, (state.projects.payload || {}) as Projects),
          (data) => data
        ),
      [projects, id]
    )
  );

  // Handlers
  const toggleConfirmHandler = useCallback(() => {
    setConfirm((prevState) => !prevState);
  }, []);

  const removeFootageHandler = useCallback(
    (index: Integer) => {
      const list = [...videosList];
      list.splice(index, 1);
      formMethods.setValue('videos', list);
    },
    [videosList, formMethods]
  );
  const removeExpenseHandler = useCallback(
    (index: Integer) => {
      const list = [...expensesList];
      list.splice(index, 1);
      formMethods.setValue('additional_expenses', list);
    },
    [expensesList, formMethods]
  );

  const updateProjectHandler = useCallback(() => {
    if (selfie_timestamp) {
      updateProjectById({
        id: +id,
        project: {
          videos: videosList,
          user_status: 'on_review',
          additional_expenses: expensesList,
          latitude,
          longitude,
          selfie_file,
          selfie_timestamp: selfie_timestamp || DateTime.now().toISOTime(),
          selfie_uploaded_at: DateTime.now().toISOTime(),
        },
      });
    } else {
      toast.warning('Need add your selfie');
    }
  }, [
    id,
    updateProjectById,
    videosList,
    expensesList,
    latitude,
    longitude,
    selfie_file,
    selfie_timestamp,
  ]);

  const updateFootageProjectHandler = useCallback(() => {
    updateProjectById({
      id: +id,
      project: {
        videos: videosList,
        user_status: 'on_review',
        additional_expenses: expensesList,
        can_reupload_content: false,
      },
    });
  }, [id, updateProjectById, videosList]);

  // Effects
  useEffect(() => {
    if (initFetch) {
      fetchProjectById(id);
    }
  }, [fetchProjectById, id, initFetch]);

  return {
    confirm,
    currentProject,
    updateProjectHandler,
    removeFootageHandler,
    removeExpenseHandler,
    toggleConfirmHandler,
    formMethods,
    videosList,
    expensesList,
    updateFootageProjectHandler,
  };
};

const useProjectOfferView = (
  formMethods: UseFormReturn<UpdateProjectOfferStepParams['project']>,
  initFetch = false
) => {
  // Setup
  const {id} = useParams<{id: string}>();
  const {projects, fetchProjectById, updateProjectById} = useProjects();

  // Data
  const [user_step] = formMethods.watch(['user_step']);

  const currentProject = useAppSelector(
    useMemo(
      () =>
        createSelector(
          (state: Store.State) =>
            findCurrentProject(id, (state.projects.payload || {}) as Projects),
          (data) => data
        ),
      [projects, id]
    )
  );

  const updateProjectUserStepHandler = useCallback(() => {
    updateProjectById({
      id: +id,
      project: {
        user_step: user_step,
      },
    });
  }, [id, user_step, updateProjectById]);

  // Effects
  useEffect(() => {
    if (initFetch) {
      fetchProjectById(id);
    }
  }, [fetchProjectById, id, initFetch]);

  return {
    currentProject,
    updateProjectUserStepHandler,
    formMethods,
  };
};

export {useProjects, useProjectView, useProjectOfferView};
