import {DateTime} from 'luxon';
import {FillEmptyStringParams} from 'types';

export const capitalizeFirstLetter = (data = ''): string =>
  `${data.charAt(0).toUpperCase()}${data.slice(1)}`;

export const humanize = function (string = ''): string {
  return string
    .replace(/\w/, (c) => c.toUpperCase())
    .replace(/_/g, ' ')
    .replace(/\sid/g, ' ID');
};

export const humanizeDate = (date: string, format = 'dd.LL.yyyy'): string =>
  DateTime.fromISO(date).toFormat(format);

export const fillEmptyStr = ({
  list = [],
  config = {} as FillEmptyStringParams['config'],
}: FillEmptyStringParams): string => {
  const _c = {separator: '', default: 'N/A', ...config};
  const str = (
    list.reduce((prev, curr) => {
      return typeof curr === 'string'
        ? `${prev}${_c.separator}${curr.trim()}`
        : prev;
    }, '') as string
  ).trim();

  return str.length ? str : _c.default;
};

export const convertDMS = (lat = 0, lng = 0) => {
  const toDegreesMinutesAndSeconds = (coordinate: number) => {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = (minutesNotTruncated - minutes) * 60;
    return `${degrees}°${minutes}'${seconds.toFixed(1)}"`;
  };

  const latitude = toDegreesMinutesAndSeconds(lat);
  const latitudeCardinal = lat >= 0 ? 'N' : 'S';

  const longitude = toDegreesMinutesAndSeconds(lng);
  const longitudeCardinal = lng >= 0 ? 'E' : 'W';

  return `${latitude}${latitudeCardinal} ${longitude}${longitudeCardinal}`;
};
