import {toast} from 'react-toastify';
import {
  FETCH_USER_FAILURE,
  FETCH_USER_LOADING,
  FETCH_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
} from './types';
import {sendRequest} from 'hooks/useRequest';
import {login, logout} from 'store/services/session';
import {
  IConfirmation,
  IConfirmationParams,
  IUpdateUserParams,
  IUser,
  UserError,
} from 'interfaces/user.interface';
import {ResponseError} from 'types';

export const fetchUserAction = (withEvent = true) => {
  return (dispatch: Store.AppDispatch) => {
    function fetchUser() {
      dispatch({type: FETCH_USER_LOADING});

      sendRequest<IUser | UserError>({path: '/current_user'})
        .then((res) => {
          if (res.isSuccess) {
            dispatch({type: FETCH_USER_SUCCESS, payload: res.body});
            dispatch(login());
          } else {
            dispatch(logout());
            throw (res.body as UserError).error;
          }
        })
        .catch((e) => dispatch({type: FETCH_USER_FAILURE, error: e}));
    }

    fetchUser();
    if (withEvent) {
      document.addEventListener('visibilitychange', function () {
        if (document.visibilityState === 'visible') {
          fetchUser();
        }
      });
    }
  };
};

export const updateUserAction = (
  params: IUpdateUserParams
): ((dispatch: Store.AppDispatch) => Promise<boolean>) => {
  return (dispatch: Store.AppDispatch) => {
    dispatch({type: UPDATE_USER_LOADING});

    return sendRequest<IUser | UserError>({
      path: '/current_user',
      body: {
        current_user: params,
      },
      config: {
        method: 'PATCH',
      },
    })
      .then((res) => {
        if (res.isSuccess) {
          dispatch({type: UPDATE_USER_SUCCESS, payload: res.body});
        } else {
          throw (res.body as UserError).error;
        }
        return res.isSuccess;
      })
      .catch((e) => {
        dispatch({type: UPDATE_USER_FAILURE, error: e});
        return false;
      });
  };
};

export const getConfirmationUserAction = (token: string) => {
  return (dispatch: Store.AppDispatch) => {
    dispatch({type: FETCH_USER_LOADING});

    sendRequest<IConfirmation | ResponseError>({
      path: `/user/confirmations/${token}`,
    })
      .then((res) => {
        if (res.isSuccess) {
          dispatch({
            type: FETCH_USER_SUCCESS,
            payload: res.body,
            isConfirmation: true,
          });
        } else {
          throw (res.body as ResponseError).errors[0];
        }
      })
      .catch((e) => dispatch({type: FETCH_USER_FAILURE, error: e}));
  };
};

export const sendConfirmationUserAction = ({
  token,
  body: user,
}: {
  token: string;
  body: IConfirmationParams;
}): ((dispatch: Store.AppDispatch) => Promise<boolean>) => {
  return (dispatch) => {
    dispatch({type: FETCH_USER_LOADING});

    return sendRequest<IUser | ResponseError>({
      path: `/user/confirmations/${token}`,
      body: {user},
      config: {
        method: 'PUT',
      },
    })
      .then((res) => {
        if (res.isSuccess) {
          dispatch({
            type: FETCH_USER_SUCCESS,
            payload: res.body,
          });
          dispatch(login());

          document.addEventListener('visibilitychange', function () {
            if (document.visibilityState === 'visible') {
              dispatch(fetchUserAction());
            }
          });

          toast.success('Thanks for you registration 😉!');
        } else {
          const errors = Object.values((res.body as ResponseError).errors[0]);
          throw errors[0];
        }
        return res.isSuccess;
      })
      .catch((e) => {
        dispatch({type: FETCH_USER_FAILURE, error: e});
        return false;
      });
  };
};
