import {useCallback, useMemo} from 'react';
import {createSelector} from 'reselect';
import {useHistory} from 'react-router-dom';
import {
  fetchUserAction,
  updateUserAction,
  getConfirmationUserAction,
  sendConfirmationUserAction,
} from './actions';
import {useAppDispatch, useAppSelector} from 'store';
import {useQueryParams} from 'hooks';
import {ConfirmationRouter} from 'routes/auth-routers';
import {ConfirmationGuidelinesRouter} from 'routes/auth-routers';
import {ProjectsRoute} from 'routes/user-routes';

const useUser = () => {
  // Setup
  const dispatch = useAppDispatch();
  const {
    params: {confirmation_token},
  } = useQueryParams<{confirmation_token: string}>();
  const history = useHistory();

  // Data
  const user = useAppSelector(
    useMemo(
      () =>
        createSelector(
          (state: Store.State) => state.user,
          (data) => data
        ),
      []
    )
  );

  // Handlers
  const fetchUser = useCallback(() => {
    const isConfirmation =
      history.location.pathname === ConfirmationRouter.getPath();

    dispatch(
      isConfirmation
        ? getConfirmationUserAction(confirmation_token)
        : fetchUserAction()
    );
  }, [dispatch, history.location.pathname]);

  const sendUserConfirmation = useCallback(
    async (body) => {
      const isSuccessConfirmation = await dispatch(
        sendConfirmationUserAction({
          token: confirmation_token,
          body,
        })
      );
      if (isSuccessConfirmation) {
        history.push(ConfirmationGuidelinesRouter.getPath());
      }
    },
    [dispatch]
  );

  const confirmedTechnicalRequirements = useCallback(async () => {
    const isSuccess = await dispatch(
      updateUserAction({
        technical_requirements_accepted: true,
      })
    );
    if (isSuccess) {
      history.push(ProjectsRoute.getPath());
    }
  }, [dispatch]);

  return {
    user,
    fetchUser,
    sendUserConfirmation,
    confirmedTechnicalRequirements,
  };
};

export {useUser};
