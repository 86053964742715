import React from 'react';
import {toast} from 'react-toastify';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {Link, useHistory} from 'react-router-dom';
import {ActionCableConsumer} from 'react-actioncable-provider';
import {useSession} from 'store';
import {SignInRouter} from 'routes/auth-routers';
import {ArchiveRoute, ProfileRoute, ProjectsRoute} from 'routes/user-routes';
import {fillEmptyStr} from 'utils';
import {useHeaderStyles} from 'components/layout/Header/header.styles';
import {useTextStyles} from 'styles';
import {DashboardRoute} from 'routes/content-routes';
import {useUser} from 'store/user';

const Header = () => {
  const textStyles = useTextStyles();
  const headerStyles = useHeaderStyles();
  const {user} = useUser();
  const {isAuthorized, logoutHandler} = useSession();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
    null
  );
  const hasPrivateAccess = isAuthorized && !user.isConfirmation;

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ActionCableConsumer
      channel={{
        channel: 'UserNotificationsChannel',
        id: user.payload?.id,
      }}
      onReceived={(data: void | {message_type: string; message: string}) => {
        if (data) {
          if (data.message_type === 'error') {
            toast.error(data.message);
          } else {
            toast.success(data.message);
          }
        }
      }}
    >
      <AppBar position='sticky'>
        <Toolbar>
          <Box display={'flex'} flexGrow={1}>
            <Box mr={5}>
              <Typography variant='h6'>
                <Link className={textStyles.link} to={DashboardRoute.getPath()}>
                  <Box className={headerStyles.logo} />
                </Link>
              </Typography>
              <Typography className={headerStyles.logoSub} component={'p'}>
                stringer
              </Typography>
            </Box>

            {hasPrivateAccess && (
              <>
                <Button
                  color='inherit'
                  onClick={() => history.push(ProjectsRoute.getPath())}
                >
                  Projects
                </Button>
                <Button
                  color='inherit'
                  onClick={() => history.push(ArchiveRoute.getPath())}
                >
                  Archive
                </Button>
              </>
            )}
          </Box>
          <Box alignItems={'center'} display={'flex'}>
            {hasPrivateAccess ? (
              <>
                <Typography variant='subtitle2'>
                  {fillEmptyStr({
                    list: [user.payload?.first_name, user.payload?.last_name],
                    config: {default: user.payload?.email, separator: ' '},
                  })}
                </Typography>
                <div>
                  <IconButton
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    aria-label='account of current user'
                    color='inherit'
                    onClick={handleMenu}
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    id='menu-appbar'
                    keepMounted
                    onClose={handleClose}
                    open={Boolean(anchorEl)}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        history.push(ProfileRoute.getPath());
                      }}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                  </Menu>
                </div>
              </>
            ) : (
              <>
                <Button
                  color='inherit'
                  onClick={() => history.push(SignInRouter.getPath())}
                  type='button'
                >
                  Sign In
                </Button>
                {/*<Button*/}
                {/*  color='inherit'*/}
                {/*  onClick={() => history.push(SignUpRouter.getPath())}*/}
                {/*  type='button'*/}
                {/*>*/}
                {/*  Sign Up*/}
                {/*</Button>*/}
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </ActionCableConsumer>
  );
};
export default Header;
