import React from 'react';
import createRoute from './create-router';

const DashboardPage = React.lazy(() =>
  import(/* webpackChunkName: "dashboard-page" */ 'pages/Dashboard')
);

const TOSPage = React.lazy(() =>
  import(/* webpackChunkName: "tos-page" */ 'pages/TOS')
);

const PrivacyPolicyPage = React.lazy(() =>
  import(/* webpackChunkName: "privacy-policy-page" */ 'pages/PrivacyPolicy')
);

export const DashboardRoute = createRoute({
  path: '/',
  component: DashboardPage,
  exact: true,
});

export const TOSRoute = createRoute({
  path: '/tos',
  component: TOSPage,
  exact: true,
});

export const PrivacyPolicyRoute = createRoute({
  path: '/privacy-policy',
  component: PrivacyPolicyPage,
  exact: true,
});
