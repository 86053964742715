import {
  FETCH_USER_FAILURE,
  FETCH_USER_LOADING,
  FETCH_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
} from './types';
import type {IConfirmation, IUser} from 'interfaces/user.interface';
import {RequestAction} from 'types';

const initialUserState = {
  loading: true,
  payload: undefined,
  init: false,
  isConfirmation: false,
};

export const userReducer = (
  state = initialUserState,
  {
    type,
    payload,
    error,
    isConfirmation = false,
  }: RequestAction<IUser | IConfirmation> & {isConfirmation?: boolean}
): Store.State['user'] => {
  switch (type) {
    case FETCH_USER_LOADING:
    case UPDATE_USER_LOADING:
      return {...state, loading: true};
    case FETCH_USER_SUCCESS:
      return {...state, payload, init: true, loading: false, isConfirmation};
    case FETCH_USER_FAILURE:
    case UPDATE_USER_FAILURE:
      return {...state, payload, loading: false, error};
    case UPDATE_USER_SUCCESS:
      return {...state, payload, loading: false};

    default:
      return state;
  }
};
