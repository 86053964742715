import React from 'react';
import createRoute from './create-router';
import {Integer} from 'types';

const ProfilePage = React.lazy(() =>
  import(/* webpackChunkName: "profile-page" */ 'pages/Profile')
);

const ProjectsPage = React.lazy(() =>
  import(/* webpackChunkName: "projects-page" */ 'pages/Projects')
);

const ArchivePage = React.lazy(() =>
  import(/* webpackChunkName: "archive-page" */ 'pages/Archive')
);

const ProjectOfferAcceptPage = React.lazy(() =>
  import(
    /* webpackChunkName: "project-offer-accept-page" */ 'pages/ProjectOfferAccept'
  )
);

const ProjectViewPage = React.lazy(() =>
  import(/* webpackChunkName: "project-view-page" */ 'pages/ProjectView')
);

export const ProfileRoute = createRoute({
  path: '/profile',
  component: ProfilePage,
});

export const ProjectsRoute = createRoute({
  path: '/projects',
  component: ProjectsPage,
  exact: true,
});

export const ArchiveRoute = createRoute({
  path: '/archive',
  component: ArchivePage,
  exact: true,
});

export const ProjectViewRoute = createRoute<{id: Integer}>({
  path: '/projects/:id',
  component: ProjectViewPage,
  exact: true,
});

export const ProjectOfferAcceptRoute = createRoute<{id: Integer}>({
  path: '/projects/accept/:id',
  component: ProjectOfferAcceptPage,
  exact: true,
});
