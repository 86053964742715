import {LOGIN, LOGOUT} from './types';
import {ISessionState} from 'interfaces/servises.interface';

export interface Action {
  type: typeof LOGIN | typeof LOGOUT;
}

const initialState: ISessionState = {
  isAuthorized: false,
};

export default (state = initialState, action: Action): ISessionState => {
  switch (action.type) {
    case LOGIN:
      return {...state, isAuthorized: true};
    case LOGOUT:
      return {...state, isAuthorized: false};
    default:
      return state;
  }
};
