import {toast} from 'react-toastify';
import {
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_LOADING,
  FETCH_PROJECTS_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  UPDATE_PROJECT_LOADING,
  UPDATE_PROJECT_SUCCESS,
} from './types';
import {sendRequest} from 'hooks/useRequest';
import {
  Integer,
  UpdateProjectOfferStepParams,
  UpdateProjectParams,
} from 'types';
import {IProject, Projects} from 'interfaces/projects.interface';
import store from 'store';
import {makeFormData, makePlainObject} from 'utils';

export const fetchProjectsAction = () => {
  return (dispatch: Store.AppDispatch) => {
    dispatch({type: FETCH_PROJECTS_LOADING});

    sendRequest<Projects>({path: '/user/projects'})
      .then((res) => {
        if (res.isSuccess) {
          dispatch({type: FETCH_PROJECTS_SUCCESS, payload: res.body});
        } else {
          throw 'Projects response has an error.';
        }
      })
      .catch((e) => dispatch({type: FETCH_PROJECTS_FAILURE, error: e}));
  };
};

export const fetchProjectByIdAction = (id: Integer) => {
  return (dispatch: Store.AppDispatch) => {
    dispatch({type: FETCH_PROJECTS_LOADING});

    sendRequest<IProject>({
      path: `/user/projects/${id}`,
    })
      .then((res) => {
        if (res.isSuccess) {
          const state = (store.getState().projects.payload || {}) as Projects;
          const prSectionName = (() => {
            switch (res.body?.user_status) {
              case 'new_task': {
                return 'new_assignments';
              }
              case 'denied':
              case 'finished':
                return 'finalized';

              default:
                return 'in_progress';
            }
          })();

          const prIndex = state[prSectionName].findIndex((pr) => pr.id === id);

          if (prIndex !== -1) {
            state[prSectionName][prIndex] = res.body!;
          } else {
            state[prSectionName].push(res.body!);
          }

          dispatch({
            type: FETCH_PROJECTS_SUCCESS,
            payload: state,
          });
        } else {
          throw 'Project fetching has error ☹️.';
        }
      })
      .catch((e) => {
        dispatch({type: FETCH_PROJECTS_FAILURE, error: e});
      });
  };
};

export const updateProjectByIdAction = (
  params: UpdateProjectParams | UpdateProjectOfferStepParams,
  withToast: boolean
) => {
  return (dispatch: Store.AppDispatch) => {
    dispatch({type: UPDATE_PROJECT_LOADING});
    const body = makeFormData(makePlainObject(params));

    sendRequest<IProject>({
      path: `/user/projects/${params.id}`,
      body,
      config: {
        method: 'PATCH',
      },
    })
      .then((res) => {
        if (res.isSuccess) {
          dispatch({type: UPDATE_PROJECT_SUCCESS});
          dispatch(fetchProjectsAction());
          if (withToast) {
            toast.success('Project has success updated!');
          }
        } else {
          if (withToast) {
            toast.error('Project has an error on updated!');
          }
        }
      })
      .catch((e) => {
        dispatch({type: UPDATE_PROJECT_FAILURE, error: e});
      });
  };
};

export const deleteProjectByIdAction = (id: Integer) => {
  return (dispatch: Store.AppDispatch) => {
    dispatch({type: UPDATE_PROJECT_LOADING});

    sendRequest<IProject>({
      path: `/user/projects/${id}`,
      config: {
        method: 'DELETE',
      },
    })
      .then((res) => {
        if (res.isSuccess) {
          dispatch(fetchProjectsAction());
          toast.success('Project has been removed!');
        } else {
          toast.error('Project has an error on deleted!');
        }
      })
      .catch((e) => {
        dispatch({type: UPDATE_PROJECT_FAILURE, error: e});
      });
  };
};
