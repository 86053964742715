import React, {Suspense, useEffect} from 'react';
import {ToastContainer} from 'react-toastify';
import {Box, MuiThemeProvider, CssBaseline} from '@material-ui/core';
import MainRouter from 'routes';
import {useWrapStyles} from 'styles';
import Loader from 'components/layout/Loader';
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';
import {useUser} from 'store/user';
import {globalStyles} from 'styles/global.styles';
import {useCountries} from 'store/services/countires';

function App() {
  const wrapStyles = useWrapStyles();
  const {fetchUser} = useUser();
  const {fetchCountries} = useCountries();

  useEffect(() => {
    fetchCountries();
    fetchUser();
  }, []);

  return (
    <MuiThemeProvider theme={globalStyles}>
      <CssBaseline />
      <Box className={wrapStyles.wrapper}>
        <Header />
        <div className='page'>
          <Suspense fallback={<Loader withTopIndent />}>
            <MainRouter />
          </Suspense>
        </div>
        <Footer />
        <ToastContainer />
      </Box>
    </MuiThemeProvider>
  );
}

export default App;
